import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  DatePicker,
  Select,
  message,
  Checkbox,
  Switch,
  Upload,
  Space,
  TimePicker,
  Button,
  Radio,
} from "antd";
import styled from "@emotion/styled";
import moment from "moment";
import {
  NON_EMPTY_REGEX,
  EMAIL_REGEX,
  countryList,
  Greetings,
  AwardList,
  STATUS_CODE,
} from "common/Constants";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { isEmpty } from "lodash";
import { CALL_API } from "common/API";

const StyleRow = styled(Row)`
  padding: 10px;
  box-shadow: 0px 3px 6px #00000015;
  .ant-form-item-label {
    text-align: left;
  }
  .ant-picker {
    width: 100% !important;
  }
  .not_applicable {
    /* background:red; */
    margin-top: -15px;
  }
  .ant-upload.ant-upload-select {
    width: 100% !important;
    color: #397ff4 !important;
    .ant-btn > span {
      display: inline-block;
      color: #397ff4;
    }
    button,
    html [type="button"],
    [type="reset"],
    [type="submit"] {
      background: #f2f2f2;
    }
    .ant-upload-list-picture .ant-upload-list-item,
    .ant-upload-list-picture-card .ant-upload-list-item {
      height: 40px;
    }
    .ant-upload-list-text .ant-upload-list-item-name,
    .ant-upload-list-picture .ant-upload-list-item-name {
      color: #397ff4;
    }
  }
`;

function PersonalForm({ setStep, setFormValues, formValues, form }) {
  const { Option } = Select;
  const containerRef = useRef(null);

  const [emailsList, setEmailList] = useState([]);
  const [applicable, setApplicable] = useState({
    workpermitnoisapplicable: false,
    workpermitexpirydateisapplicable: false,
  });

  const getEmailList = async () => {
    const response = await CALL_API(`add-users/email-list`, "get");
    if (response.code === STATUS_CODE.SUCCESS) setEmailList(response.response);
  };

  useEffect(() => {
    getEmailList();
    if (!isEmpty(formValues)) {
      form.setFieldsValue(formValues);
    }
  }, [formValues]);

  return (
    <div>
      <>
        <b className="my-2 mx-1">Personal Information:</b>
        <StyleRow className="mt-2 mb-3" gutter={20}>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name="firstname"
              label="First Name"
              rules={[
                {
                  required: false,
                  pattern: NON_EMPTY_REGEX,
                  message: "First name is required",
                },
              ]}
            >
              <Input
                placeholder="First name"
                maxLength={50}
                disabled
                onKeyDown={(e) => {
                  const allowedChars = /^[a-zA-Z\s]+$/;
                  if (!allowedChars.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name="lastname"
              label="Last Name"
              rules={[
                {
                  required: false,
                  pattern: NON_EMPTY_REGEX,
                  message: "Last name is required",
                },
              ]}
            >
              <Input
                placeholder="Last name"
                maxLength={50}
                disabled
                onKeyDown={(e) => {
                  const allowedChars = /^[a-zA-Z\s]+$/;
                  if (!allowedChars.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name="salutation"
              label="Salutation"
              placeholder="Select Salutation"
              rules={[
                {
                  required: true,
                  pattern: NON_EMPTY_REGEX,
                  message: "Salutation is required",
                },
              ]}
            >
              <Select
                placeholder="Select Salutation"
                getPopupContainer={() => containerRef.current}
              >
                <Option value="Mr.">Mr.</Option>
                <Option value="Dr.">Dr.</Option>
                <Option value="Prof.">Prof.</Option>
                <Option value="Ms.">Ms.</Option>
                <Option value="Mrs.">Mrs.</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name="othernames"
              label="Other Names"
            >
              <Input
                placeholder="Other name"
                maxLength={50}
                onKeyDown={(e) => {
                  const allowedChars = /^[a-zA-Z\s]+$/;
                  if (!allowedChars.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} ref={containerRef}>
            <Form.Item
              label="Date of Birth"
              name="dateofbirth"
              rules={[
                {
                  required: false,
                  validator: async (_, value) => {
                    if (value && value.isAfter(moment('2007-01-01'))) {
                      return Promise.reject(new Error('Date of Birth must be before 2007'));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <DatePicker
                className="w-100"
                format="DD-MM-YYYY"
                placeholder="Select Date of Birth"
                getPopupContainer={() => containerRef.current}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} ref={containerRef}>
            <Form.Item
              name="gender"
              label="Gender"
              rules={[
                {
                  required: false,
                  pattern: NON_EMPTY_REGEX,
                  message: "Gender is required",
                },
              ]}
            >
              <Select
                disabled
                placeholder="Select gender"
                getPopupContainer={() => containerRef.current}
              >
                <Option value="male">Male</Option>
                <Option value="female">Female</Option>
                <Option value="They/ Them">They/ Them</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name="dependent"
              label="No. of Dependants"
              rules={[
                {
                  required: true,
                  pattern: NON_EMPTY_REGEX,
                  message: "No. of Dependants is required",
                },
              ]}
            >
              <Input
                placeholder="No. of Dependants"
                maxLength={10}
                onKeyDown={(e) => {
                  const value = /^[0-9+]$/?.test(e.key);
                  if (!value && e.key !== "Backspace" && /^[0-9+]$/) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} ref={containerRef}>
            <Form.Item
              name="maritalstatus"
              label="Marital Status"
              rules={[
                {
                  required: true,
                  pattern: NON_EMPTY_REGEX,
                  message: "This field is required",
                },
              ]}
            >
              <Select
                placeholder="Select"
                getPopupContainer={() => containerRef.current}
              >
                <Option value="single">Single</Option>
                <Option value="married">Married</Option>
                <Option value="widowed">Widowed</Option>
                <Option value="divorced">Divorced</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name="phoneno"
              label="Mobile Number"
              rules={[
                {
                  required: true,
                  pattern: NON_EMPTY_REGEX,
                  message: "Mobile Number is required",
                },
              ]}
            >
              <PhoneInput
                placeholder="Mobile Number"
                maxLength={20}
                onKeyDown={(e) => {
                  const value = /^[0-9+]$/?.test(e.key);
                  if (!value && e.key !== "Backspace" && /^[0-9+]$/) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name="user_id"
              label="Email address"
              rules={[
                {
                  required: false,
                  pattern: EMAIL_REGEX,
                  message: "User ID is required",
                },
                // { validator: validateEmployeeId },
              ]}
            >
              <Input placeholder="User ID" maxLength={60} disabled />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name="postaladdress"
              label="Postal address"
              rules={[
                {
                  required: true,
                  message: "Postal address is required",
                },
              ]}
            >
              <Input
                placeholder="Postal address"
                maxLength={60}
                onKeyDown={(e) => {
                  const value =
                    /^[0-9,/]$/.test(e.key) || /^[a-zA-Z\s,/]+$/.test(e.key);
                  if (!value && e.key !== "Backspace") {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name="physicaladdress"
              label="Physical address"
              rules={[
                {
                  required: true,
                  message: "Physical address is required",
                },
              ]}
            >
              <Input
                placeholder="Physical address"
                maxLength={60}
                onKeyDown={(e) => {
                  const value =
                    /^[0-9,/]$/.test(e.key) || /^[a-zA-Z\s,/]+$/.test(e.key);
                  if (!value && e.key !== "Backspace") {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} ref={containerRef}>
            <Form.Item
              name="country"
              label="Country"
              rules={[{ required: true, message: "Country is required" }]}
            >
              <Select
                showSearch
                placeholder="Search to Select"
                optionFilterProp="children"
                getPopupContainer={() => containerRef.current}
                disabled={form.getFieldValue('country') !==""}
              >
                {countryList?.map((country, index) => (
                  <Select.Option key={index} value={country} label={country}>
                    {country}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} ref={containerRef}>
            <Form.Item
              name="greetings"
              label="Greetings"
              rules={[{ required: true, message: "Greetings is required" }]}
            >
              <Select
                showSearch
                placeholder="Search to Select"
                optionFilterProp="children"
                getPopupContainer={() => containerRef.current}
              >
                {Greetings?.map((value, index) => (
                  <Select.Option key={index} value={value} label={value}>
                    {value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name="national_id"
              label="National ID/Passport Number"
              rules={[
                {
                  required: true,
                  message: "National ID/Passport Number is required",
                },
              ]}
            >
              <Input
                placeholder="National ID/Passport Number"
                maxLength={20}
                onKeyDown={(e) => {
                  const value =
                    /^[0-9]$/?.test(e.key) || /^[a-zA-Z\s]+$/?.test(e.key);
                  if (!value && e.key !== "Backspace" && /^[0-9]$/) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} ref={containerRef}>
            <Form.Item
              name="nationality"
              label="Nationality"
              rules={[{ required: true, message: "Nationality is required" }]}
            >
              <Select
                showSearch
                placeholder="Search to Select"
                optionFilterProp="children"
                getPopupContainer={() => containerRef.current}
              >
                {countryList?.map((country, index) => (
                  <Select.Option key={index} value={country} label={country}>
                    {country}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name="workpermitno"
              label="Work Permit No"
              rules={[
                {
                  required: !applicable.workpermitnoisapplicable,
                  message: "Work Permit No is required",
                },
              ]}
            >
              <Input
                placeholder="Work Permit No"
                type="text"
                maxLength={10}
                onKeyDown={(e) => {
                  const value = /^[0-9]$/?.test(e.key);
                  if (!value && e.key !== "Backspace" && /^[0-9]$/) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              name="workpermitnoisapplicable"
              className="not_applicable"
              valuePropName="checked"
            >
              <Checkbox
                checked={applicable.workpermitnoisapplicable}
                onChange={(e) =>
                  setApplicable((prevState) => ({
                    ...prevState,
                    workpermitnoisapplicable: e.target.checked,
                  }))
                }
              >
                Not Applicable
              </Checkbox>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} ref={containerRef}>
            <Form.Item
              label="Work permit expiry date"
              name="workpermitexpirydate"
              rules={[
                {
                  required:
                    !form.getFieldsValue().workpermitexpirydateisapplicable,
                  pattern: NON_EMPTY_REGEX,
                  message: "Work permit expiry date is required",
                },
              ]}
            >
              <DatePicker
                className="w-100"
                format="DD-MM-YYYY"
                placeholder="Select Work permit expiry date"
                getPopupContainer={() => containerRef.current}
              />
            </Form.Item>
            <Form.Item
              name="workpermitexpirydateisapplicable"
              className="not_applicable"
              valuePropName="checked"
            >
              <Checkbox
                checked={applicable.workpermitexpirydateisapplicable}
                onChange={(e) =>
                  setApplicable((prevState) => ({
                    ...prevState,
                    workpermitexpirydateisapplicable: e.target.checked,
                  }))
                }
              >
                Not Applicable
              </Checkbox>
            </Form.Item>
          </Col>

          {/* <Col xs={24} sm={24} md={12}>
            <Form.Item
              name="krapin"
              label="KRA PIN Number"
              rules={[
                { required: true, message: "KRA PIN Number is required" },
              ]}
            >
              <Input
                placeholder="KRA PIN Number"
                maxLength={20}
                onKeyDown={(e) => {
                  const value =
                    /^[0-9]$/?.test(e.key) || /^[a-zA-Z\s]+$/?.test(e.key);
                  if (!value && e.key !== "Backspace" && /^[0-9]$/) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name="nssf"
              label="NSSF Number"
              rules={[{ required: true, message: "NSSF Number is required" }]}
            >
              <Input
                placeholder="NSSF Number"
                maxLength={20}
                onKeyDown={(e) => {
                  const value =
                    /^[0-9]$/?.test(e.key) || /^[a-zA-Z\s]+$/?.test(e.key);
                  if (!value && e.key !== "Backspace" && /^[0-9]$/) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name="nhif"
              label="NHIF Number"
              rules={[{ required: true, message: "NHIF Number is required" }]}
            >
              <Input
                placeholder="NHIF Number"
                maxLength={20}
                onKeyDown={(e) => {
                  const value =
                    /^[0-9]$/?.test(e.key) || /^[a-zA-Z\s]+$/?.test(e.key);
                  if (!value && e.key !== "Backspace" && /^[0-9]$/) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Col>
          {/*
           */}

          {/* <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            name="initial"
                            label="Initial"
                            rules={[
                                {
                                    required: false,
                                    pattern: NON_EMPTY_REGEX,
                                    message: "Initial is required",
                                },
                            ]}
                        >
                            <Input
                                placeholder="Initial"
                                maxLength={3}
                                onKeyDown={(e) => {
                                    const allowedChars = /^[a-zA-Z\s]+$/;
                                    if (!allowedChars.test(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col> */}

          {/*
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            name="presentaddress"
                            label="Address"
                            rules={[
                                {
                                    required: false,
                                    message: "Address is required",
                                },
                            ]}
                        >
                            <Input
                                placeholder="Address"
                                maxLength={60}
                                onKeyDown={(e) => {
                                    const value =
                                        /^[0-9,/]$/.test(e.key) ||
                                        /^[a-zA-Z\s,/]+$/.test(e.key);
                                    if (!value && e.key !== "Backspace") {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>
                     */}
        </StyleRow>
        <b className="my-2 mx-1">Next Of Kin Details:</b>
        <StyleRow className="mt-2 mb-3" gutter={20}>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name="emergencycontactname"
              label="Contact Name"
              rules={[
                {
                  required: true,
                  pattern: NON_EMPTY_REGEX,
                  message: "Contact Name is required",
                },
              ]}
            >
              <Input
                placeholder=" Contact Name"
                maxLength={15}
                onKeyDown={(e) => {
                  const allowedChars = /^[a-zA-Z\s]+$/;
                  if (!allowedChars.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name="emergencycontactnumber"
              label="Contact Number"
              rules={[
                {
                  required: true,
                  pattern: NON_EMPTY_REGEX,
                  message: "Contact Name is required",
                },
              ]}
            >
              <PhoneInput
                placeholder="Contact Number"
                maxLength={20}
                onKeyDown={(e) => {
                  const value = /^[0-9+]$/?.test(e.key);
                  if (!value && e.key !== "Backspace" && /^[0-9+]$/) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name="nextofkinrelationship"
              label="Relationship"
              rules={[
                {
                  required: true,
                  pattern: NON_EMPTY_REGEX,
                  message: "Relationship is required",
                },
              ]}
            >
              <Input
                placeholder="Relationship"
                maxLength={15}
                onKeyDown={(e) => {
                  const allowedChars = /^[a-zA-Z\s]+$/;
                  if (!allowedChars.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name="nextofkinpostaladdress"
              label="Postal address"
              rules={[
                {
                  required: true,
                  message: "Postal address is required",
                },
              ]}
            >
              <Input
                placeholder="Postal address"
                maxLength={60}
                onKeyDown={(e) => {
                  const value =
                    /^[0-9,/]$/.test(e.key) || /^[a-zA-Z\s,/]+$/.test(e.key);
                  if (!value && e.key !== "Backspace") {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Col>
        </StyleRow>
        <div className="text-end"></div>
      </>
    </div>
  );
}

export default PersonalForm;
