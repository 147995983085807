
import React, { useEffect, useRef, useState } from 'react'
import {
    Row,
    Col,
    Form,
    Input,
    DatePicker,
    Select,
    message,
    Checkbox,
    Switch,
    Upload,
    Space,
    TimePicker,
    Button
} from "antd";
import styled from "@emotion/styled";
import moment from "moment";
import {
    NON_EMPTY_REGEX,
    EMAIL_REGEX,
    countryList,
    Greetings,
    AwardList,
} from "common/Constants";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { isEmpty } from 'lodash';
import { CALL_API } from 'common/API';

function generateUniqueId() {
  const usedIds = [];
  let uniqueId = "";
  while (uniqueId.length !== 6) {
    const randomNumber = Math.floor(Math.random() * 900000) + 100000;
    if (!usedIds.includes(randomNumber.toString())) {
      usedIds.push(randomNumber.toString());
      uniqueId += randomNumber.toString();
    }
  }
  return uniqueId;
}


const StyledButton = styled(Button)`
  background: #26a4ff;
  border-radius: 5px;
  color: #ffffff;
  width: 100px;
  margin: 10px;
`;
const StyleWrap = styled(Form)`
.not_applicable_prof{
  height: 10px;
  margin-left: 0px;
  /* margin: 0; */
}
.add_tab{
  /* margin-left: 0px; */
  /* margin-bottom: 10px; */
}
`;

const StyleRow = styled(Row)`
  padding: 10px;
  box-shadow: 0px 3px 6px #00000015;
  .ant-form-item-label {
    text-align: left;
  }
  .ant-picker{
    width: 100% !important;
  }
  .ant-btn.ant-btn-default.ant-btn-lg.w-100.text-start{
    justify-content: flex-start;
  }
  .attach_file{
    background: #c2fae5 !important;
    span{
      color:#1b1b1bb3 !important;
    }
  }
  .ant-upload.ant-upload-select {
    width: 100% !important;
    color: #397ff4 !important;
    .ant-btn > span {
      display: inline-block;
      color: #397ff4;
    }
    button,
    html [type="button"],
    [type="reset"],
    [type="submit"] {
      background: #f2f2f2;
    }
    .ant-upload-list-picture .ant-upload-list-item,
    .ant-upload-list-picture-card .ant-upload-list-item {
      height: 40px;
    }
    .ant-upload-list-text .ant-upload-list-item-name,
    .ant-upload-list-picture .ant-upload-list-item-name {
      color: #397ff4;
    }
  }
`;

function EducationInfo({setStep, goBack, setFormValues, formValues, form, certificationTypeValue, setCertificationTypeValue}) {
    const { Option } = Select;
    const containerRef = useRef(null);
    const [applicable, setApplicable] = useState({
      professional:false,
      practical:false
  });
    const onFinish = (values) => {
        const currentFormValues = form.getFieldValue();
        setFormValues((prevValues) => ({
            ...prevValues,
            ...currentFormValues,
          }));
        setStep(2)
        console.log('Received values:', values);
        // Handle API calls or state updates here
    };

    
  const uploadFile = async (value) => {
    const { file } = value;
    try {
      if (!isEmpty(file)) {
        
        const { user_id } = formValues;
        let obj = {
          userId: user_id?.toLowerCase(),
          name: file.name,
          type: file.type,
          // IdentityName:"Education",
          uniqueId: file.uniqueId || file.uid,
          content: file.base64Data || "",
        }
        await CALL_API(`upload-userdocuments/BDIX4EW`, "post", obj);
      }
    } catch (error) {
      console.error("[handlesubmit] error --->", error);
    }
  };

    
    return (
        <div>
             <>
              <b>Education Details:</b>
              <Form.List name="educationdetails">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <StyleRow gutter={16}>
                        <Col xs={24} sm={24} md={24}>
                          <Form.Item
                            {...restField}
                            label="Institute Name"
                            name={[name, "institutename"]}
                            rules={[
                              {
                                required: true,
                                message: "Institute Name required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Institute Name"
                              // onChange={onFinish}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            {...restField}
                            label="From:"
                            name={[name, "fromdate"]}
                            rules={[
                              { required: true, message: "Date required" },
                            ]}
                          >
                            <DatePicker
                              className="w-100"
                              format="DD-MM-YYYY"
                              // onChange={() => {
                              //   onFinish();
                              // }}
                              placeholder="From"
                              getPopupContainer={() => containerRef.current}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            {...restField}
                            label="To:"
                            name={[name, "todate"]}
                            rules={[
                              { required: true, message: "Date required" },
                            ]}
                          >
                            <DatePicker
                              className="w-100"
                              format="DD-MM-YYYY"
                              // onChange={() => {
                              //   onFinish();
                              // }}
                              placeholder="To"
                              getPopupContainer={() => containerRef.current}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            {...restField}
                            label="Exam Body:"
                            name={[name, "exambody"]}
                            rules={[
                              {
                                required: true,
                                message: "Exam Body required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Exam Body"
                              // onChange={onFinish}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            {...restField}
                            label="Awarded:"
                            name={[name, "awarded"]}
                            rules={[
                              { required: true, message: "Awarded required" },
                            ]}
                          >
                            <Select
                              placeholder="Select"
                              getPopupContainer={() => containerRef.current}
                            >
                              <Option value="Doctorate">Doctorate</Option>
                              <Option value="Master">Master</Option>
                              <Option value="Bachelor">Bachelor</Option>
                              <Option value="Diploma">Diploma</Option>
                              <Option value="Certificate">Certificate</Option>
                            </Select>
                          </Form.Item>
                          {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                        </Col>
                        <Col xs={24} sm={24} md={24}>
                          <Form.Item
                            {...restField}
                            label="Attachment"
                            name={[name, "attachment"]}
                            valuePropName="fileList"
                            rules={[
                              {
                                required: true,
                                message: "Attachment required",
                              },
                            ]}
                            getValueFromEvent={(e) => {
                              if (Array.isArray(e)) {
                                return e;
                              }
                              return e && e.fileList;
                            }}
                          >
                            <Upload
                              customRequest={({ file, onSuccess }) => {
                                const reader = new FileReader();
                                reader.readAsDataURL(file);

                                reader.onload = () => {
                                  const base64Data = reader.result.split(",")[1];
                                  const uniqueId = generateUniqueId();
                                  file.base64Data = base64Data; // Add base64Data to file object
                                  file.uniqueId = uniqueId; // Add uniqueId to file object
                                  onSuccess({ file });
                                };

                                reader.onerror = (error) => {
                                  console.error("File read error:", error);
                                };
                              }}
                              name="logo"
                              // action="/upload.do"
                              onChange={(value) => {
                                //  onFinish();
                                uploadFile(value);
                              }}
                            
                              listType="text"
                              style={{ width: "100%" }}
                            >
                              <div style={{ width: "100%" }}>
                                <Button
                                  className="w-100 text-start attach_file"
                                  size="large"
                                  style={{fontWeight:"600"}}
                                >
                                  Upload File
                                </Button>
                              </div>
                            </Upload>
                          </Form.Item>
                        </Col>
                      </StyleRow>
                    ))}
                    <Form.Item style={{marginLeft:"0px", marginTop:"30px", color:"#397EF3"}}>
                      <u
                        className="bg-transparent border-0 text-start"
                        type="dashed"
                        style={{cursor: "pointer"}}
                        onClick={() => add()}
                        block
                      >
                        +Add Another
                      </u>
                    </Form.Item>
                  </>
                )}
              </Form.List>
              
              <b>Professional Certifications:</b>
              <Form.List name="professionalcertifications">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <StyleRow gutter={16} className="mt-2">
                        <Col xs={24} sm={24} md={24}>
                          <Form.Item
                            {...restField}
                            label="Certification Type"
                            name={[name, "certificationtype"]}
                            rules={[
                              {
                                required: !(applicable.professional),
                                message: "Certification Type required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Select"
                              optionFilterProp="children"
                              getPopupContainer={() => containerRef.current}
                              defaultValue={AwardList[0]}
                              onChange={(values)=>setCertificationTypeValue(form.getFieldValue())}
                            >
                              {AwardList?.map((value, index) => (
                                <Select.Option
                                  key={index}
                                  value={value}
                                  label={value}
                                >
                                  {value}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        {certificationTypeValue?.professionalcertifications?.map((item, index) => (
                            index === key && item?.certificationtype === "Others" && (
                              <Col xs={24} sm={24} md={24} key={index}>
                                <Form.Item
                                  {...restField}
                                  label="Certification Name"
                                  name={[name, "certificationname"]}
                                  rules={[
                                    {
                                      required: !(applicable.professional),
                                      message: "Certification required",
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                            )
                          ))}
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            {...restField}
                            label="From:"
                            name={[name, "fromdate"]}
                            rules={[
                              { required: !(applicable.professional), message: "Date required" },
                            ]}
                          >
                            <DatePicker
                              className="w-100"
                              format="DD-MM-YYYY"
                              // onChange={() => {
                              //   onFinish();
                              // }}
                              placeholder="From"
                              getPopupContainer={() => containerRef.current}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            {...restField}
                            label="To:"
                            name={[name, "todate"]}
                            rules={[
                              { required: !(applicable.professional), message: "Date required" },
                            ]}
                          >
                            <DatePicker
                              className="w-100"
                              format="DD-MM-YYYY"
                              // onChange={() => {
                              //   onFinish();
                              // }}
                              placeholder="To"
                              getPopupContainer={() => containerRef.current}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24}>
                          <Form.Item
                            {...restField}
                            label="Remarks"
                            name={[name, "remarks"]}
                            rules={[
                              { required: !(applicable.professional), message: "Remarks required" },
                            ]}
                          >
                            <Input
                              placeholder="Remarks"
                              // onChange={() => {
                              //   onFinish();
                              // }}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24}>
                          <Form.Item
                            {...restField}
                            label="Attachment"
                            name={[name, "attachment"]}
                            valuePropName="fileList"
                            rules={[
                              {
                                required: !(applicable.professional),
                                message: "Attachment required",
                              },
                            ]}
                            getValueFromEvent={(e) => {
                              if (Array.isArray(e)) {
                                return e;
                              }
                              return e && e.fileList;
                            }}
                          >
                            <Upload
                              customRequest={({ file, onSuccess }) => {
                                const reader = new FileReader();
                                reader.readAsDataURL(file);

                                reader.onload = () => {
                                  const base64Data = reader.result.split(",")[1];
                                  const uniqueId = generateUniqueId();
                                  file.base64Data = base64Data; // Add base64Data to file object
                                  file.uniqueId = uniqueId; // Add uniqueId to file object
                                  onSuccess({ file });
                                };

                                reader.onerror = (error) => {
                                  console.error("File read error:", error);
                                };
                              }}
                              name="logo"
                              // action="/upload.do"
                              onChange={(value) => {
                                //  onFinish();
                                uploadFile(value);
                              }}
                            
                              listType="text"
                              style={{ width: "100%" }}
                            >
                              <div style={{ width: "100%" }}>
                                <Button
                                  className="w-100 text-start attach_file"
                                  size="large"
                                  style={{fontWeight:"600"}}
                                >
                                  Upload File
                                </Button>
                              </div>
                            </Upload>
                          </Form.Item>
                        </Col>
                      </StyleRow>
                      
                    ))}
                    <StyleWrap>
                        <Form.Item
                            name="professionalisapplicable"
                            className='not_applicable_prof my-2'
                            valuePropName="checked"
                            
                        >
                            <Checkbox
                                checked={applicable.professional}
                                onChange={(e) =>
                                setApplicable((prevState) => ({
                                    ...prevState,
                                    professional: e.target.checked,
                                }))
                                }
                            >
                                Not Applicable
                            </Checkbox>                               
                          </Form.Item>

                        
                    </StyleWrap>
                    <Form.Item style={{marginLeft:"0px", marginTop:"30px", color:"#397EF3"}}>
                      <u
                        className="bg-transparent border-0 text-start"
                        type="dashed"
                        style={{cursor: "pointer"}}
                        onClick={() => add()}
                        block
                      >
                        +Add Another
                      </u>
                    </Form.Item>
                    
                  </>
                )}
              </Form.List>
              <b>Practical Trainings:</b>
              <Form.List name="practicaltrainings">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <StyleRow gutter={16} className="mt-2">
                        <Col xs={24} sm={24} md={24}>
                          <Form.Item
                            {...restField}
                            label="Organisation Name"
                            name={[name, "organisationname"]}
                            rules={[
                              {
                                required: !(applicable.practical),
                                message: "Organisation Name required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Organisation Name"
                              // onChange={() => {
                              //   // onFinish();
                              // }}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            {...restField}
                            label="From:"
                            name={[name, "fromdate"]}
                            rules={[
                              { required: !(applicable.practical), message: "Date required" },
                            ]}
                          >
                            <DatePicker
                              className="w-100"
                              format="DD-MM-YYYY"
                              // onChange={() => {
                              //   // onFinish();
                              // }}
                              placeholder="From"
                              getPopupContainer={() => containerRef.current}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            {...restField}
                            label="To:"
                            name={[name, "todate"]}
                            rules={[
                              { required: !(applicable.practical), message: "Date required" },
                            ]}
                          >
                            <DatePicker
                              className="w-100"
                              format="DD-MM-YYYY"
                              // onChange={() => {
                              //   // onFinish();
                              // }}
                              placeholder="To"
                              getPopupContainer={() => containerRef.current}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24}>
                          <Form.Item
                            {...restField}
                            label="Position Held"
                            name={[name, "positionheld"]}
                            rules={[
                              {
                                required: !(applicable.practical),
                                message: "Position Held required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Position Held"
                              // onChange={() => {
                              //   // onFinish();
                              // }}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24}>
                          <Form.Item
                            {...restField}
                            label="Nature of work & Tasks performed or completed"
                            name={[name, "tasksperformed"]}
                            rules={[
                              {
                                required: !(applicable.practical),
                                message:
                                  "Nature of work & Tasks performed or completed required",
                              },
                            ]}
                          >
                            <Input.TextArea
                              placeholder="Nature of work & Tasks performed or completed"
                              // onChange={() => {
                              //   // onFinish();
                              // }}
                            />
                          </Form.Item>
                          {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                        </Col>
                      </StyleRow>
                    ))}
                      <StyleWrap>
                        <Form.Item
                            name="practicalisapplicable"
                            className='not_applicable_prof my-2'
                            valuePropName="checked"
                            
                        >
                             <Checkbox
                                checked={applicable.practical}
                                onChange={(e) =>
                                setApplicable((prevState) => ({
                                    ...prevState,
                                    practical: e.target.checked,
                                }))
                                }
                            >
                                Not Applicable
                            </Checkbox>       
                        </Form.Item>

                        
                    </StyleWrap>
                    <Form.Item style={{marginLeft:"0px", marginTop:"30px", color:"rgb(57, 126, 243)"}}> 
                      <u
                        className="bg-transparent border-0 text-start"
                        type="dashed"
                        style={{cursor: "pointer"}}
                        onClick={() => add()}
                        block
                      >
                        +Add Another
                      </u>
                    </Form.Item>
                  </>
                )}
              </Form.List>
             
            </>
           
        </div>
    )
}

export default EducationInfo